import React from 'react';

export const useGeoLocationFromIpAddress = () => {
  const [location, setLocation] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      setLocation(data);
    })();
  }, []);
  return location;
};
