import React, { FC } from 'react';
import { Spacer } from '@nextui-org/react';
import HomeHero from '../components/HomeHero';
import BoatPreviewCarousel from '../components/BoatPreviewCarousel';
import { useBoats } from '../hooks/boats';
import { useGeoLocationFromIpAddress } from '../hooks/useGeoLocation';

const Home: FC<Props> = () => {
  const geoLocation = useGeoLocationFromIpAddress();

  return (
    <>
      <Spacer y={2} />
      <HomeHero />
      <Spacer y={4} />
      {geoLocation?.region && (
        <BoatPreviewCarousel
          title={`Boats in ${geoLocation?.region}`}
          filters={{ location: { state: geoLocation?.region_code } }}
        />
      )}
      <BoatPreviewCarousel title="Power boats" filters={{ power: true }} />
      <BoatPreviewCarousel title="Sail boats" filters={{ sail: true }} />
      <BoatPreviewCarousel
        title="Used boats"
        filters={{ condition: { used: true } }}
      />
      <BoatPreviewCarousel
        title="New boats"
        filters={{ condition: { new: true } }}
      />
    </>
  );
};

export default Home;
