import React from 'react';
import Image from 'next/image';
import { Container, Grid, Text, Card, Button, Spacer } from '@nextui-org/react';
import Routes from '../routes';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { track } from '@amplitude/analytics-browser';
// import MainImage from '../images/catamaran-financing.png';

const HomeHero = () => {
  const router = useRouter();

  return (
    <div
      css={{
        position: 'relative',
        height: '100%',
        padding: '0 !important',
      }}
    >
      {/* <div
        css={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <div
          css={{
            maxWidth: '50%',
          }}
        >
          <Image
            layout="intrinsic"
            alt="Bali Catamaran sailboat at anchor on black background"
            src={MainImage}
            width={400}
            height={400}
            placeholder="blur"
          />
        </div>
      </div> */}
      <div>
        <div>
          <div>
            <Text
              h1
              css={{
                maxWidth: '400px',

                '@media (max-width: 554px)': {
                  maxWidth: '200px',
                },
              }}
            >
              The modern way to buy a boat
            </Text>
            <Text
              css={{
                maxWidth: '60%',
                '@media (max-width: 554px)': {
                  maxWidth: '100%',
                },
              }}
            >
              Introducing a revolutionary platform for buying, selling and
              financing boats
            </Text>
            <Spacer />
            <div
              gap={0}
              css={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                width: '100%',
              }}
            >
              <Button
                onClick={({ target }) => {
                  track('home hero button click', { button: 'browse boats' });
                  router.push(Routes['boats-search']);
                }}
                auto
                ghost
                size="lg"
                color="gradient"
              >
                Browse boats
              </Button>

              {/* <Link href={Routes['boats-search']}>Browse boats</Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
